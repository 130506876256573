.navbar {
  background-color: #ffffff;
  padding: 10px 2px;
  position: fixed; /* Fixed position */
  top: 0; /* Attach to the top */
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure it appears above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); /* Add a subtle shadow for better separation */
  
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Add a max-width */
  margin: 0 auto; /* Center the container */
  padding: 0 0px; /* Ensure padding on sides to prevent overflow */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  width: 100%;
}

.logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.nav-item {
  margin: 0 15px;
}

.nav-item a {
  text-decoration: none;
  color: rgb(0, 0, 0); /* Ensure links are black */
  font-size: 1em;
  padding: 10px 15px;
  display: block;
}

.nav-item a:hover {
  background-color: #f0f0f0; /* Subtle hover effect */
  color:#3a3aa2;
  border-radius: 5px;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px; /* Added margin-right */
}

.search-bar input {
  padding: 8px 15px;
  border: 1px solid #ddd; /* Light border color */
  border-radius: 5px;
  font-size: 1em;
  max-width: 100%; /* Ensure input doesn't overflow */
}

.search-bar input::placeholder {
  color: #aaa;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .nav-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    color: black; /* Ensure the menu icon is visible */
    font-size: 1.5em;
    padding: 10px;
  }
}

@media (max-width: 1100px) {
  .nav-item a {
    text-decoration: none;
    color: black; /* Ensure links are visible */
    font-size: 1em;
    padding: 3px 2px;
    display: block;
  }
}

@media (min-width: 769px) {
  .sidebar {
    display: none;
  }
}

@media (max-width: 480px) {
  .search-bar {
    flex-grow: 1;
    margin-right: 10px; /* Reduce margin-right on smaller screens */
  }

  .search-bar input {
    width: 100%;
  }

  .logo img {
    height: 30px;
  }
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: #333;
  overflow-x: hidden;
  transition: 0.3s;
  transform: translateX(100%);
  z-index: 1000; /* Ensure it appears above other content */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 1.2em;
  color: white;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  background-color: #575757;
}

.sidebar .close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
}

.search-bar {
  position: relative; /* Ensure the suggestions are positioned relative to this container */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px; /* Added margin-right */
}

.search-bar input {
  padding: 8px 15px;
  border: 1px solid #ddd; /* Light border color */
  border-radius: 5px;
  font-size: 1em;
  max-width: 100%; /* Ensure input doesn't overflow */
}

.search-bar input::placeholder {
  color: #aaa;
}

/* Suggestions Styling */
.suggestions {
  position: absolute;
  top: 100%; /* Directly under the search bar */
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  z-index: 1000;
  max-height: 300px; /* Max height for the suggestions dropdown */
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.suggestion-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer; /* Ensure cursor is pointer on hover */
}

.suggestion-item:hover {
  background-color: #f0f0f0; /* Example background color on hover */
}

.suggestion-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: #333;
  overflow-x: hidden;
  transition: 0.3s;
  transform: translateX(100%);
  z-index: 1000; /* Ensure it appears above other content */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar a {
  padding: 15px 20px;
  text-decoration: none;
  font-size: 1.2em;
  color: white;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #555; /* Add a subtle border between links */
}

.sidebar a:last-child {
  border-bottom: none; /* Remove border from the last link */
}

.sidebar a:hover {
  background-color: #575757; /* Darken background on hover */
}

.sidebar .close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
}

/* Additional styles for sidebar */
.sidebar-header {
  padding: 20px;
  background-color: #222; /* Darker background for header */
}

.sidebar-header h2 {
  color: white;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.sidebar-links {
  padding: 10px 0;
}

.sidebar-links a {
  display: block;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.sidebar-links a:hover {
  background-color: #444;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .sidebar {
    width: 80%; /* Adjust width for smaller screens */
    max-width: 300px; /* Limit maximum width */
  }

  .sidebar .close-btn {
    top: 15px;
    right: 15px;
  }

  .sidebar-header h2 {
    font-size: 1.3em;
  }
}

/* Example CSS for dropdown menu */
.nav-item {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-item {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}


.dropdown-item {
  padding: 10px 20px;
  white-space: nowrap;
}

.dropdown-item a {
  text-decoration: none;
  color: black;
}

.dropdown-item a:hover {
  background-color: #f1f1f1;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.nav-item {
  margin: 0 15px;
  position: relative; /* Ensure dropdown is positioned relative to nav-item */
}

.nav-item a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 1em;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.nav-item a:hover {
  background-color: #f0f0f0;
  color: #3a3aa2;
  border-radius: 5px;
}

.dropdown-menu {
  display: none; /* Hide by default */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: max-content; /* Adjust width based on content */
}

.nav-item:hover .dropdown-menu {
  display: block; /* Show dropdown on hover */
}

.dropdown-item {
  padding: 10px 20px;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px; /* Space between text and arrow */
  transform: rotate(45deg);
}

.down {
  transform: rotate(45deg);
}
