.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  padding: 20px;
  box-sizing: border-box;
}

.squirrelImage {
  width: 300px; /* Adjust as needed */
  height: auto;
  margin-right: 40px; /* Space between the image and content */
}

.errorContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.iconWrapper {
  background-color: #ff6f61;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.icon {
  color: white;
  font-size: 50px;
}

.title {
  font-size: 80px;
  margin: 0;
  font-weight: bold;
  color: #ff6f61;
}

.message {
  font-size: 18px;
  margin: 10px 0 30px;
}

.homeLink {
  text-decoration: none;
  color: #ff6f61;
  font-size: 18px;
  border: 2px solid #ff6f61;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.homeLink:hover {
  background-color: #ff6f61;
  color: white;
}
